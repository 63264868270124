@font-face {
  font-family: 'Neuropol X Free';
  src: url('./assets/fonts/neuropol_x_rg.otf') format('opentype');
  font-weight: 400; /* normal */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neuropol X Free Italic';
  src: url('./assets/fonts/neuropol_x_rg.otf') format('opentype');
  font-weight: 400; /* normal */
  font-style: italic;
  font-display: swap;
}

.neuropol {
  font-family: 'Neuropol X Free', sans-serif;
}

.uppercase {
  text-transform: uppercase;
}

.appBarTitle {
  font-weight: 900;
}

.titles {
  font-size: 1.35rem;
  font-weight: 700;
}

.subtitles {
  font-size: 1.15rem;
  font-weight: 700;
}

.menu {
  font-size: 0.9rem;
  font-weight: 700;
}

.pageLink {
  font-size: 0.85rem;
  font-weight: 500;
  color: black;
}

.ltblue {
  color: #00e1fc;
}

.blue {
  color: #009bfb; /* replace with desired color */
}

.grey {
  color: rgb(142, 142, 142); /* replace with desired color */
}